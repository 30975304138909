.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.m-0 {
  margin: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 4px !important;
}

.mb-2 {
  margin-bottom: 8px !important;
}

.mb-3 {
  margin-bottom: 12px !important;
}

.mb-4 {
  margin-bottom: 24px !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 4px !important;
}

.mt-2 {
  margin-top: 8px !important;
}

.mt-3 {
  margin-top: 12px !important;
}

.mt-4 {
  margin-top: 24px !important;
}
.mt-5 {
  margin-top: 30px !important;
}

.ml-2 {
  margin-left: 8px !important;
}

.m-auto {
  margin: auto;
}

.hide {
  opacity: 0;
}

.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.justify-content-space-between {
  justify-content: space-between !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.cursor-grab {
  cursor: grab;
}

.text-align-center {
  text-align: center;
}

.organization-filter-dropdown {
  ul {
    max-height: 250px !important;
    overflow-y: auto;
  }
}

.overly-menu-note {
  & ul {
    background: #fdedd6 !important;
    display: flex;
    flex-direction: column;
    gap: 8px 0;
    & .ant-dropdown-menu-item {
      padding: 3px 5px !important;
    }
    & .ant-dropdown-menu-item:hover {
      background-color: unset !important;
      opacity: 0.8;
    }
  }
}

.overly-menu-note-items {
  & ul {
    background: #f5fbfc !important;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    max-width: 568px;
    max-height: 288px;
    padding: 16px !important;

    overflow-y: auto;
    gap: 8px;
    overflow-x: hidden;

    @-moz-document url-prefix() {
      width: 560px;
    }

    & .awards-list {
      padding: 0px !important;
      max-height: unset;
    }

    @media (max-width: 590px) {
      & {
        max-width: 480px;
        @-moz-document url-prefix() {
          width: 470px;
        }
      }
    }
    @media (max-width: 500px) {
      & {
        max-width: 392px;
        @-moz-document url-prefix() {
          width: 382px;
        }
      }
    }
    @media (max-width: 390px) {
      & {
        max-width: 304px;
        @-moz-document url-prefix() {
          width: 294px;
        }
      }
    }
    @media (max-width: 300px) {
      max-width: 206px;
      & {
        max-width: 206px;
      }
    }
    & .ant-dropdown-menu-item {
      padding: 0 !important;
    }
    & .ant-dropdown-menu-item:hover {
      background-color: unset !important;
      opacity: 0.8;
    }
  }
  &-columns {
    & ul {
      flex-direction: column;
      background: var(--light-yellow) !important;
      padding: 16px !important;
      gap: 8px 0;
    }
  }
}

.menu-media-action ul {
  padding: 0 !important;
}

.overly-menu-note-items-live_object {
  & ul {
    max-width: fit-content;
  }
}

.overly-menu-note-items-live_object-items,
.overly-menu-note-items-whiteboard_show-items {
  & ul {
    & ant-dropdown-menu-item {
      span {
        div {
          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
    li:last-of-type {
      width: 100%;
      height: 48px;
    }
  }
}

.overly-menu-note-items-media-items {
  & > ul {
    li:has(.menu-audio-item-action) {
      width: 100%;
    }
    > li:last-of-type {
      width: 100%;
      height: 48px;
    }
  }
}

.menu-audio-item-action {
  > ul {
    li {
      width: 100%;
      height: 70px;
      padding: 7px 20px;
      box-shadow: none;
    }
  }
}

.icon-menu-actions {
  &-item {
    > svg {
      width: 16px;
      height: 16px;
    }

    &-clear {
      > svg path:nth-child(2) {
        fill: none;
      }
    }
  }
}
.icon-menu-live_object {
  > svg {
    width: 20px;
    height: 20px;
    > path {
      fill: var(--orange-solid);
    }
  }
}

.resize-marker {
  position: absolute;
  width: 10px;
  height: 10px;
  background-color: blue;
  border-radius: 50%;
}

.resize-marker.top-left {
  top: -5px;
  left: -5px;
  cursor: nwse-resize;
}

.resize-marker.top-right {
  top: -5px;
  right: -5px;
  cursor: nesw-resize;
}

.resize-marker.bottom-left {
  bottom: -5px;
  left: -5px;
  cursor: nesw-resize;
}

.resize-marker.bottom-right {
  bottom: -5px;
  right: -5px;
  cursor: nwse-resize;
}

.resize-corner-base {
  width: 8px !important;
  height: 8px !important;

  &-bottom-left {
    bottom: -4px !important;
    left: -4px !important;
  }

  &-bottom-right {
    bottom: -4px !important;
    right: -4px !important;
  }

  &-top-left {
    top: -4px !important;
    left: -4px !important;
  }
  &-top-right {
    top: -4px !important;
    right: -4px !important;
  }
}
.resize-corner {
  position: absolute;
  user-select: none;
  width: 4px !important;
  height: 4px !important;
  background-color: blue;
  border-radius: 0;
}

.resize-corner.top-left {
  top: -2px !important;
  left: -2px !important;
  cursor: nw-resize;
}

.resize-corner.top-right {
  top: -2px !important;
  right: -2px !important;
  cursor: ne-resize;
}

.resize-corner.bottom-left {
  bottom: -2px !important;
  left: -2px !important;
  cursor: se-resize;
}

.resize-corner.bottom-right {
  bottom: -2px !important;
  right: -2px !important;
  cursor: sw-resize;
}

.dorp-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.fnt-mulish {
  font-family: 'Mulish', sans-serif;
}

.fnt-open-sans {
  font-family: 'Open Sans', sans-serif;
}

.fnt-rounded {
  font-family: 'M PLUS Rounded 1c';
}

.custom-tooltip .ant-tooltip-arrow {
  display: none;
}

.custom-tooltip .ant-tooltip-inner {
  padding: 12px;
  font-family: 'Rounded Mplus 1c', 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 23.76px;
  background-color: #fff;
  color: var(--hint-color);
  box-shadow: 0px 3.45px 13.79px 0px #00000012;
  border-radius: 16px;
  text-align: center;
}

.custom-tooltip.ant-tooltip {
  max-width: 300px !important;
}

.hide-lg {
  display: flex;
  flex-direction: column;
  flex: 1;
  @media (max-width: 450px) {
    display: none;
  }
}

.hide-sm {
  @media (min-width: 451px) {
    display: none;
  }
  // height: 100%;
  width: 100%;
  display: inherit;
}

.ant-select-dropdown-tag {
  background-color: #f5fbfc !important;
  .ant-select-item-option-selected {
    background-color: #f5fbfc !important;
    display: none;
  }
  .ant-select-item-option-active {
    background-color: transparent !important;
  }
  .ant-select-item {
    padding: 5px 6px !important;
    min-height: 24px;
    &-active {
      background-color: #f5fbfc !important;
    }
    &:hover {
      background-color: #f5fbfc !important;
    }
  }
}

.spin-full {
  width: 100%;
  height: 100%;
}

.accounts-table table {
  background-color: var(--light-gray);
  border-collapse: separate;
  border-spacing: 0 8px;
}

.spin-hint {
  span {
    > i {
      background-color: var(--hint-color) !important;
    }
  }
}

.billing-picker {
  @media (max-width: 768px) {
    max-height: 300px;
    overflow-y: auto;
    .ant-picker-panels {
      flex-direction: column !important;
    }
  }
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.timecode-time {
  color: var(--orange-solid);
  background-color: var(--light-yellow);
  padding: 0 8px;
  border-radius: 8px;
  margin-left: 5px;
}
