@function map-collect($maps...) {
  $collection: ();

  @each $map in $maps {
    $collection: map-merge($collection, $map);
  }
  @return $collection;
}

$gradients: (
  'gradient-purple': linear-gradient(163deg, #c07bf6 -8.9%, #5822cc 100%),
  'gradient-green': linear-gradient(180deg, #7de3a6 0%, #47c579 100%),
  'gradient-orange': linear-gradient(180deg, #ffd08a 0%, #ed6400 100%)
);

$colors: (
  'primary-color': #7e42fc,
  'violet-solid': #9492ee,
  'orange-solid': #ff8832,
  'primary-text': #062e3a,
  'second-gray': #c8c5d3,
  'light-gray': #f5fbfc,
  'light-description': #7da1a9,
  'light-blue': #d7f0f4,
  'light-violet': #e1d9f9,
  'light-pink': #ffe5e5,
  'light-yellow': #fdedd6,
  'dark-green': #56c323,
  'label-green': #47c579,
  'label-orange': #efa066,
  'description-solid': #3d6c77,
  'hint-color': #afccd2,
  'text-disabled': #d0e3e7,
  'text-secondary': #a29eb6,
  'text-red': #ef5a55,
  'text-yellow': #ffc100,
  'text-white': #ffffff,
  'text-green': #7de3a6,
  'text-title': #0b3d4c,
  'text-basic': #231b45,
  'text-dusty-blue': #7982a6,
  'bg-basic': #f0f1f5,
  'icon-gray': #d0e3e7,
  'additional-blue': #90d1f1
);

:root {
  // generate css vars
  @each $color, $value in $colors {
    --#{$color}: #{$value};
  }

  @each $color, $value in $gradients {
    --#{$color}: #{$value};
  }
}

@each $color, $value in $colors {
  // generate classes for color
  #{'.' + $color} {
    color: $value !important;
  }
  // generate classes for background-color
  #{'.bg-' + $color} {
    background-color: $value;
  }
}

@each $color, $value in $gradients {
  // generate classes for background
  #{'.bg-' + $color} {
    background: $value;
  }
}
